import { Stack } from '@mantine/core'
import { memo } from 'react'

import PriceToggle from 'components/search/moviemodal/PriceToggle'
import useDisplayStore from 'states/useDisplayStore'
import type { MovieWithShowtimes, Showtime, Theater } from 'types/showtimes'
import TheaterMovieCard from './TheaterMovieCard'

type Props = {
  theater: Theater
  movies: MovieWithShowtimes[]
}

function TheaterMovieList({ theater, movies }: Props) {
  const date = useDisplayStore((state) => state.currentDate)
  if (!date) {
    return null
  }

  return (
    <Stack>
      <PriceToggle />
      {movies.map((resp: MovieWithShowtimes) => {
        const movieShowtimes = resp.theaters.reduce(
          (acc: Showtime[], movieTheater) => {
            if (movieTheater.id === theater.id) {
              const dayShowtimes = movieTheater.showtimes.filter(
                ({ showtime }) => showtime.isSame(date, 'day')
              )
              acc.push(...dayShowtimes)
            }
            return acc
          },
          []
        )
        if (movieShowtimes.length > 0) {
          return (
            <TheaterMovieCard
              key={resp.movie.id}
              theater={theater}
              movie={resp.movie}
              showtimes={movieShowtimes}
            />
          )
        }
        return null
      })}
    </Stack>
  )
}

export default memo(TheaterMovieList)
