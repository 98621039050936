import dayjs from 'dayjs'
import { pick } from 'lodash'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { bestRange } from 'queries/showtimes'
import type {
  DateTimeParams,
  DurationParams,
  Place,
  ReleaseMood,
  SearchParams,
} from 'types/showtimes'
import { DEFAULT_ADDRESS, FAVORITE_ADDRESS, computeStartEnd } from 'utils/utils'

interface IState {
  place: Place
  searchParams: SearchParams
  movieId: string | undefined
  releaseMood: ReleaseMood
  dateTimeParams: DateTimeParams
  favoriteTheaterIds: number[]
  searchForFavoriteTheaters: boolean
  emptyFavoriteError: boolean
  showPrices: boolean
  ageFilter: 'all' | 'children'
  updateAgeFilter: (ageFilter: 'all' | 'children') => void
  setFavoriteTheaterIds: (favoriteTheaterIds: number[]) => void
  isFavorite: (theaterId: number) => boolean
  toggleFavoriteTheaterId: (theaterId: number) => void
  updateSearchLocation: (
    latitude: number,
    longitude: number,
    movieId?: string
  ) => void
  updateSearchParams: (newSearchParams: SearchParams) => void
  updateMovieId: (newMovieId: string | undefined, updateRange?: boolean) => void
  updateReleaseMood: (newReleaseMood: ReleaseMood) => void
  updateDurationParams: (newDurationParams: DurationParams) => void
  updatePlace: (place: Place) => void
  updateDateTimeParams: (DateTimeParams: DateTimeParams) => void
  resetFilters: () => void
  applySearchFavoriteTheaters: () => void
  unapplySearchFavoriteTheaters: () => void
  setShowPrices: (showPrices: boolean) => void
}

const useSearchStore = create<IState>()(
  devtools(
    persist(
      (set, get) => ({
        place: {
          address: DEFAULT_ADDRESS,
          id: null,
        },
        searchParams: {
          range: 5,
        },
        movieId: undefined,
        releaseMood: undefined,
        dateTimeParams: {},
        favoriteTheaterIds: [],
        searchForFavoriteTheaters: false,
        emptyFavoriteError: false,
        ageFilter: 'all',
        showPrices: true,
        updateSearchParams: (newSearchParams: SearchParams) => {
          const updatedSearchParams = {
            ...get().searchParams,
            ...newSearchParams,
          }
          const definedSearchParams = Object.entries(
            updatedSearchParams
          ).reduce(
            (acc, [key, value]) =>
              // biome-ignore lint/performance/noAccumulatingSpread: I don't want to break everything
              value === undefined ? acc : { ...acc, [key]: value },
            {}
          )
          set({ searchParams: definedSearchParams })
        },
        updateSearchLocation: async (latitude: number, longitude: number) => {
          const { movieId } = get()
          const range = await bestRange(latitude, longitude, movieId)
          if (range === null && get().place.id === null) {
            // Hardcode the location to Paris if the user is too far from any theater
            set({
              place: { address: 'Paris, France', id: null },
              searchParams: {
                ...get().searchParams,
                latitude: 48.8527,
                longitude: 2.3506,
                range: 20,
                theaterIds: undefined,
              },
            })
          } else {
            get().updateSearchParams({
              latitude,
              longitude,
              range: range || 100,
              theaterIds: undefined,
            })
          }
          set({ searchForFavoriteTheaters: false })
          get().updateSearchParams({ theaterIds: undefined })
        },
        updateMovieId: async (
          movieId: string | undefined,
          updateRange = false
        ) => {
          set({ movieId })
          if (updateRange) {
            const { latitude, longitude } = get().searchParams
            if (latitude && longitude) {
              const range = await bestRange(latitude, longitude, movieId)
              get().updateSearchParams({ range: range || 100 })
            }
          }
        },
        updateReleaseMood: (updatedMood: ReleaseMood) => {
          const newReleaseMood: ReleaseMood =
            updatedMood === get().releaseMood ? undefined : updatedMood
          const releaseLimit = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
          set({
            releaseMood: newReleaseMood,
          })
          get().updateSearchParams({
            releasedBefore: newReleaseMood === 'old' ? releaseLimit : undefined,
            releasedAfter:
              newReleaseMood === 'recent' ? releaseLimit : undefined,
          })
        },
        updateDurationParams: (newParams: DurationParams) => {
          const { minDuration, maxDuration } = newParams
          get().updateSearchParams({ minDuration, maxDuration })
        },
        updatePlace: (place: Place) => {
          set({
            place,
            searchForFavoriteTheaters: place.address === FAVORITE_ADDRESS,
          })
        },
        updateAgeFilter: (ageFilter: 'all' | 'children') => {
          set({ ageFilter })
          get().updateSearchParams({
            forChildren: ageFilter === 'all' ? undefined : true,
          })
        },
        updateDateTimeParams: (newParams: DateTimeParams) => {
          const updatedParams = {
            ...get().dateTimeParams,
            ...newParams,
          }
          const { start, end } = computeStartEnd(updatedParams)
          set({ dateTimeParams: updatedParams })
          get().updateSearchParams({ start, end })
        },
        resetFilters: () => {
          set({ ageFilter: 'all', releaseMood: undefined })
          get().updateSearchParams({
            cards: undefined,
            forChildren: undefined,
            langs: undefined,
            audioLangs: undefined,
            releasedBefore: undefined,
            releasedAfter: undefined,
            genres: undefined,
            excludeGenres: undefined,
            minDuration: undefined,
            maxDuration: undefined,
          })
        },
        applySearchFavoriteTheaters: () => {
          if (get().favoriteTheaterIds.length === 0) {
            set({ emptyFavoriteError: true })
            return
          }
          get().updatePlace({
            address: FAVORITE_ADDRESS,
            id: FAVORITE_ADDRESS,
          })
          set({ searchForFavoriteTheaters: true })
          get().updateSearchParams({
            theaterIds: get().favoriteTheaterIds,
            latitude: undefined,
            longitude: undefined,
          })
          set({ emptyFavoriteError: false })
        },
        unapplySearchFavoriteTheaters: () => {
          set({ searchForFavoriteTheaters: false, emptyFavoriteError: false })
          get().updatePlace({
            address: DEFAULT_ADDRESS,
            id: DEFAULT_ADDRESS,
          })
        },
        setFavoriteTheaterIds: (favoriteTheaterIds: number[]) => {
          set({ favoriteTheaterIds })
          if (
            favoriteTheaterIds.length === 0 &&
            get().searchForFavoriteTheaters === true
          ) {
            set({ emptyFavoriteError: true })
          }
        },
        isFavorite: (theaterId: number) =>
          get().favoriteTheaterIds.includes(theaterId),
        toggleFavoriteTheaterId: (theaterId: number) => {
          if (get().isFavorite(theaterId)) {
            get().setFavoriteTheaterIds(
              get().favoriteTheaterIds.filter((id: number) => id !== theaterId)
            )
          } else {
            get().setFavoriteTheaterIds([
              ...get().favoriteTheaterIds,
              theaterId,
            ])
          }
          if (get().searchForFavoriteTheaters) {
            get().updateSearchParams({
              theaterIds: get().favoriteTheaterIds,
            })
          }
        },
        setShowPrices: (showPrices: boolean) => {
          set({ showPrices })
        },
      }),
      {
        name: 'search-storage',
        partialize: (state: IState) => {
          let searchParams = pick(state.searchParams, [
            'cards',
            'range',
            'langs',
            'audioLangs',
            'releasedAfter',
            'releasedBefore',
            'minDuration',
            'maxDuration',
            'genres',
            'excludeGenres',
            'orderBy',
            'orderDir',
          ])
          // Ensure cards are stored as an array
          if (searchParams.cards && !Array.isArray(searchParams.cards)) {
            searchParams.cards = [searchParams.cards]
          }
          if (state.place.address !== DEFAULT_ADDRESS) {
            // Persist only locations that are not the default one
            searchParams = {
              ...searchParams,
              ...pick(state.searchParams, [
                'latitude',
                'longitude',
                'theaterIds',
              ]),
            }
          }
          return {
            ...pick(state, [
              'place',
              'releaseMood',
              'favoriteTheaterIds',
              'showPrices',
            ]),
            searchParams,
          }
        },
      }
    )
  )
)
export default useSearchStore
